@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800&subset=latin");
@import url("https://fonts.googleapis.com/css?family=Nunito:100,200,300,400,500,600,700,800,900&subset=latin");
@import url("https://fonts.googleapis.com/css?family=Mr+Dafoe");

body {
  font-family: "Montserrat";
}

.table-width.dashboard,
.rdt_Pagination,
.data-table-extensions {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.create-story-button-margin {
  padding: 10px;
  /*margin-left: 2.5%; */
  margin-bottom: 25px;
}

.data-table-extensions {
  display: flex !important;
  padding-left: 0px !important;
}

.table-width.dashboard {
  /*border-left: 1px solid rgba(0,0,0,.12) !important;
    border-right: 1px solid rgba(0,0,0,.12) !important;
    border-top: 1px solid rgba(0,0,0,.12) !important;*/
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rdt_Pagination {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
  -right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  font-size: 14px !important;
  background-color: #f1f1f1 !important;
  border-top: 1px solid #ccc !important;
}
.dashboard-table-title {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  font-weight: 500;
}

.main-dashboard {
  float: left;
  width: 82%;
  padding: 45px 20px !important;
  height: 100vh !important;
  overflow: auto;
}

.rce-popup-header {
  font-size: 18px;
  font-weight: 500;
  padding-left: 15px;
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 10px !important;
  padding-top: 15px !important;
}

.rdt_TableHeadRow {
  border-bottom: 1px solid #ccc !important;
  background-color: #f1f1f1 !important;
}

.rdt_TableCell,
.rdt_TableCol {
  margin-left: 20px !important;
  font-size: 16px !important;
}

.rdt_TableCol {
  color: #4e74de !important;
}

.button-edit {
  background-color: transparent !important;
  border-color: #4e74de !important;
  color: #4e74de !important;
  font-size: 15px !important;
  font-weight: 500;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.button-edit:hover {
  background-color: #4e74de !important;
  border-color: #4e74de !important;
  color: #fff !important;
}

.column-style {
  display: flex;
  flex-direction: column;
}

.flex-style {
  display: flex;
}

.row-style {
  display: flex;
  flex-direction: row;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-bar {
  /*background-color: rgb(48,60,84,255); */
  background-color: #481fca;
  padding: 10px;
  /*margin-left: 13%;*/
  /*z-index:99999999; */
}

.top-bar-username {
  color: white;
  float: right;
}

.button-width {
  width: 13%;
}

.app-column {
  width: 100%;
}

.field-colorchange {
  color: red;
}

.register-signin-left-panel.col-sm-12 {
  /*border: 1px solid  #b2c6fd ;*/
  background-color: white;
  padding: 35px 40px 25px 30px;
  margin-left: 40px;
  width: 95%;
}

.register-signin-left-panel.col-sm-4 {
  background-color: white;
  padding: 35px 40px 25px 30px;
  width: 95%;
}

.margin-buttons-story {
  margin-left: 10px;
}

a.nav-link {
  font-size: 17px;
  color: white !important;
}

.stories-table-margin {
  margin-left: 11%;
  padding: 15px 50px 50px 50px !important;
}

.stories-table-margin-minimized {
  margin-left: 2% !important;
  padding: 15px 50px 50px 50px !important;
}

.cursor {
  cursor: pointer;
}

input[type="radio"] {
  border: 0px;
  width: 100%;
  height: 1em;
}
.pictures-div {
  display: flex;
  flex-wrap: wrap;
}

.card-div {
  flex-grow: 1;
}

.app-container {
  float: left !important;
}

/*sidebar*/

.vertical-menu-line {
  border-left: 1px solid #481fca;
  height: 20px;
  height: 20px;
  margin-left: 26px;
  margin-top: -9px;
  display: none;
  opacity: 0.1 !important;
}

.sidebar-hide {
  color: #481fca;
  font-weight: 600;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  margin-top: -25px;
  margin-bottom: 15px;
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed {
  /*margin-top:55px; */
}
.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed .nav-link {
  font-size: 14px !important;
}

.header-icon-cont {
  /*height:55px;*/
}

.sidebar-menu-items-container {
  padding: 27px 1rem;
}

.sidebar-menu-items-container .nav-link,
.sidebar-menu-items-footer-container .nav-link {
  margin-bottom: 8px;
  color: #481fca !important;
  font-weight: 500;
}

.sidebar-menu-items-container .nav-link svg,
.sidebar-menu-items-footer-container .nav-link svg {
  color: #481fca !important;
  /*opacity: 0.2 !important; */
}

.sidebar-menu-items-container .nav-link:hover,
.sidebar-menu-items-footer-container .nav-link:hover {
  background-color: #745dec14;
}

.sidebar-menu-items-container .nav-link:hover svg,
.sidebar-menu-items-footer-container .nav-link:hover svg {
  /*opacity: 0.6 !important; */
}

.c-sidebar {
  background: #ffffff !important;
  box-shadow: 7px 0 60px rgb(0 0 0 / 5%);
  /*margin-top: 55px; */
}

.c-sidebar-footer {
  background: #ffffff !important;
  padding-bottom: 35px;
  padding-top: 35px;
  border-top: 1px solid #f1f1f1;
}

.sidebar-title-header {
  text-align: center;
  color: #e50914;
}

.titile-bold-caps {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 0px;
  color: #e50914;
}

.title-light-reg {
  font-family: "Mr Dafoe";
  font-size: 24px;
  margin-top: -11px;
  color: #e50914;
}
.c-sidebar-header {
  padding-top: 30px !important;
  /*background: rgb(60 75 100) */
  background: #fff !important;
}

.c-sidebar-header svg {
  color: #e50914 !important;
}

.sidebar-menu-items-footer-container {
  /*margin-top:477px; */
}

.sidebar-nav-items-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -webkit-fill-available;
}

.text-user-top-bar {
  font-weight: 600;
}

/*tables*/

.table-responsive {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  margin-bottom: 15px;
}

.CDataTable_arrow-position__YATEX {
  right: 6% !important;
}
.table {
  margin-bottom: 0px;
}

table.stories-data-table > tbody > tr > td:nth-child(1),
table.stories-transaltion-table > tbody > tr > td:nth-child(1) {
  width: 45vw;
  color: #481fca;
  font-weight: 500;
}
table.stories-episodes-data-table > tbody > tr > td:nth-child(2),
table.episodes-translation > tbody > tr > td:nth-child(1) {
  width: 30vw;
  color: #481fca;
  font-weight: 500;
}

table.stories-episodes-data-table > tbody > tr > td:nth-child(1) {
  text-align: center;
}

table.stories-episodes-data-table > thead > tr > th:last-child,
table.episodes-translation > thead > tr > th:nth-child(6),
table.episodes-translation > thead > tr > th:nth-child(7) {
  text-align: center;
}

table.stories-data-table > thead > tr > th:nth-child(2),
table.stories-data-table > tbody > tr > td:nth-child(2) {
  text-align: center;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}

.table td,
.table th {
  border-top-color: #f1f1f1;
  border-right: 1px solid #eaeaea;
}

.table-striped tbody tr:hover {
  background-color: #745dec14 !important;
}

.table tbody tr:focus {
  background-color: #745dec14 !important;
}

tr {
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  background-color: #fff;
}

tr:last-child {
  border-bottom: 1px solid #eaeaea;
}

thead {
  background-color: white;
  font-family: "Nunito";
  font-size: 15px;
}

.table thead th {
  border-bottom-color: #f1f1f1;
}

/*page title*/

.page-title-icon {
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0.83333rem;
  margin: 0 30px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  border-radius: 0.25rem;
  width: 60px;
  height: 60px;
  float: left;
  margin-bottom: 32px;
}

.page-title-icon svg {
  margin-left: auto;
  margin-right: auto;
}

.page-title {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 800;
  color: #481fca;
}

.validate-color-input {
  color: #ff0000 !important;
}

/*modals*/

.remove-width {
  width: unset !important;
  left: unset !important;
  max-width: unset !important;
}

.popup-modal .modal-dialog {
  max-width: 800px !important;
  margin: 5.75rem auto;
  min-width: 1650px !important;
}

.popup-modal .modal-dialog .modal-content .modal-header {
  background-color: #ffb800 !important;
  color: #fff;
  padding: 15px 20px 15px 20px;
}

div.modal.overflow-auto.fade.popup-modal .show.d-block > div > div > header {
  background-color: #ffb800;
}

.popup-modal .modal-dialog .modal-content .modal-header .close {
  padding: 18px 20px 13px 20px;
  opacity: 1;
  text-shadow: none;
  color: white;
}

.popup-modal .modal-dialog .modal-content .modal-header .modal-title {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
}

.popup-modal .modal-dialog .modal-content .modal-header .modal-title svg {
  margin-top: -3px;
}

.popup-modal .modal-dialog .modal-content {
  border: 0px !important;
}

.popup-modal .modal-dialog .modal-content .modal-body {
  padding: 40px 30px 40px 30px;
}

.create-new-story-modal .modal-dialog {
  max-width: 800px !important;
  margin: 5.75rem auto;
}

.create-new-story-modal .modal-dialog .modal-content .modal-header {
  background-color: #ffb800;
  color: #fff;
  padding: 15px 20px 15px 20px;
}

div.modal.overflow-auto.fade.create-new-story-modal.show.d-block
  > div
  > div
  > header {
  background-color: #ffb800;
}

.create-new-story-modal .modal-dialog .modal-content .modal-header .close {
  padding: 18px 20px 13px 20px;
  opacity: 1;
  text-shadow: none;
  color: white;
}

.create-new-story-modal
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
}

.create-new-story-modal
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title
  svg {
  margin-top: -3px;
}

.create-new-story-modal .modal-dialog .modal-content {
  border: 0px !important;
}

.create-new-story-modal .modal-dialog .modal-content .modal-body {
  padding: 40px 30px 40px 30px;
}

.modal-buttons-container {
  margin-left: 15px;
  margin-top: 35px;
}

.modal-row {
  margin-bottom: 20px;
}

.modal-row-stp-story {
  margin-bottom: 15px;
}

.modal-row-stp-story > div > label,
.modal-row-stp-story > label {
  margin-bottom: 0.2rem !important;
}

.label-cont-radio {
  float: left;
  margin-right: 10px;
}

.header-icon-modal {
  margin-top: -4px;
}

.radio-cont {
  float: left;
  width: 14px;
  margin-left: 0px;
  margin-top: 2px;
}

.char-type-radios {
  padding-top: 7px;
}

/*create new episode*/
.create-new-episode-modal .modal-dialog,
div.modal.overflow-auto.fade.show.d-block > div.modal-dialog {
  max-width: 600px !important;
  margin: 5.75rem auto;
}

.create-new-episode-modal .modal-dialog .modal-content .modal-header,
div.modal.overflow-auto.fade.show.d-block > div > div > header {
  background-color: #481fca;
  color: #fff;
  padding: 15px 20px 15px 20px;
}

.create-new-episode-modal .modal-dialog .modal-content .modal-header .close,
div.modal.overflow-auto.fade.show.d-block > div > div > .modal-header > .close {
  padding: 18px 20px 13px 20px;
  opacity: 1;
  text-shadow: none;
  color: white;
}

.create-new-episode-modal
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title,
div.modal.overflow-auto.fade.show.d-block > div > div > header > h5 {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
}

.create-new-episode-modal
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title
  svg {
  margin-top: -3px;
}

.create-new-story-modal .modal-dialog .modal-content {
  border: 0px !important;
}

.create-new-episode-modal .modal-dialog .modal-content .modal-body,
div.modal.overflow-auto.fade.show.d-block > div > div > div.modal-body {
  padding: 40px 30px 40px 30px;
}

/*/modals*/
/*concreteStory*/
.story-details-info {
  display: flex;
  justify-content: start;
}
.episodes-table-info {
  font-weight: 600;
}
.story-details-info p {
  margin-right: 30px;
}

.story-title,
.table-proofread-title {
  font-family: "Nunito";
  font-size: 22px;
  font-weight: 700;
}

.action-icons {
  /*opacity: 0.3;  */
  color: #481fca;
}

.no-action-icon {
  color:rgba(118, 118, 118, 0.596)
}

.action-icons:hover {
  opacity: 1;
  color: #481fca;
  opacity: 0.3;
}

.no-action-icon:hover {
  opacity: 1;
  color:rgba(118, 118, 118, 0.596);
  opacity: 0.3;
}
/*/concreteStory*/

/*stories page layout*/

.register-signin-left-panel.col-sm-12.rounded.shadow.stories,
.register-signin-left-panel.col-sm-12.rounded.shadow.setup-stories-notes,
.register-signin-left-panel.col-sm-12.rounded.shadow.setup-stories-info,
.register-signin-left-panel.col-sm-12.rounded.shadow.translation-translate {
  background-color: white;
  padding: 35px 40px 25px 30px;
  margin-left: 0px;
  width: 100%;
}

/*proofread*/

div.register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread
  > div
  > div:nth-child(1) {
  padding-left: 0px;
}

div.register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread
  > div
  > div.form-group-name.col-sm-2.ml-1.btn-fin-trans {
  padding-right: 0px;
}

.register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread,
.register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread-tables {
  background-color: white;
  padding: 30px 40px 25px 30px;
  margin-left: 0px;
  width: 100%;
}

.register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread-tables
  > div
  > div {
  box-shadow: none;
}

.register-signin-left-panel.col-sm-12.rounded.shadow.setup-stories-notes,
.register-signin-left-panel.col-sm-12.rounded.shadow.setup-stories-info,
.register-signin-left-panel.col-sm-12.rounded.shadow.translation-translate {
  margin-bottom: 30px;
}

.register-signin-left-panel.col-sm-12.rounded.shadow.setup-stories-info,
.register-signin-left-panel.col-sm-12.rounded.shadow.translation-translate,
.register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread {
  padding-bottom: 35px;
}

div.register-signin-left-panel.col-sm-12.rounded.shadow.stories
  > div.position-relative.table-responsive,
div.tab-pane.active.tab-pan-art-catalog.fade.show
  > div
  > div.position-relative.table-responsive {
  box-shadow: none;
}
/* /stories page layout*/

/*edit episode*/

.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode {
  background-color: white;
  padding: 35px 40px 25px 30px;
  margin-left: 0px;
  width: 100%;
  margin-bottom: 30px;
}

.btn-fin-review-btn,
.btn-fin-episode-btn,
.btn-fin-writing-btn {
  margin-top: 27px;
  float: right;
}

.resolved-comm-edit-stry,
.up-dwn-comm-edit-stry {
  align-self: flex-end;
}

.resolved-comm-edit-stry {
  margin-bottom: 10px;
}

.up-dwn-comm-edit-stry {
  text-align: right;
}

.up-dwn-comm-edit-stry > div > .btn > svg {
  color: #481fca;
}

.text-comm-edit-stry {
  padding-top: 8px;
}

.radio-label-comm-edit-stry {
  margin-left: 10px;
}

.button-up.comm-edit-stry:hover,
.button-down.comm-edit-stry:hover {
  background-color: #f1f1f1;
  color: #745dec;
}

.page-title.edit-episode {
  background-color: white;
  padding: 10px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  font-size: 20px;
  margin-top: 15px;
}

.page-title.edit-episode.action {
  margin-top: 50px;
}

.shadow.edit-episode.comments {
  box-shadow: 0 0.9rem 1rem rgba(0, 0, 21, 0.15) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding-top: 5px !important;
}
.shadow.edit-episode.comments.action {
  margin-bottom: 30px;
  padding-right: 60px !important;
}
.shadow.edit-episode.comments.options {
  margin-bottom: 30px;
  padding-right: 15px !important;
}

.action-col1,
.action-col2,
.action-col3 {
  border: 0px solid #f1f1f1;
  border-radius: 4px;
  padding: 35px;
  margin-right: 15px;
  background-color: #f1f1f1;
}
.action-col-title {
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.ac-sec-lbl {
  margin-top: 15px;
  font-weight: 600;
}

.ac-top-lbl {
  font-weight: 600;
}

.desc-input {
  margin-top: 15px;
  height: 60px;
}

.radio-btn-call {
  width: auto !important;
  float: left !important;
  margin-top: 3px;
}

.radio-lbl-call {
  margin-left: 5px;
  margin-right: 20px;
}

/*/edit episode*/
/*art catalog page*/
/*filter*/
.art-cat-filters-row {
  width: -webkit-fill-available !important;
  border-bottom: 1px solid #ccc;
  padding-bottom: 25px;
}
.form-group-name.col-md {
  padding-right: 0px !important;
  width: 13% !important;
}

.btn-primary-sm.wide.btn-fin-review-btn.float-right.btn {
  margin-left: 20px;
  margin-right: 10px;
}
/*/filter*/
.art-catalog-fields-cont {
  justify-content: space-between;
}

.art-catalog-fields-cont > .form-group-name {
  width: 18%;
}

.art-catalog-tabs-container {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}
.table-width.dashboard.art-catalog-tabs-container {
  width: 100% !important;
}

.tab-pan-art-catalog {
  /*border: 1px solid #c4c9d0; */
  border-radius: 4px;
  background-color: #fff;
  padding: 25px 25px 25px 35px;
  box-shadow: 0 0.8rem 1rem rgba(0, 0, 21, 0.15) !important;
}

.art-catalog-card {
  /*border:0px; */
  border-color: #eaeaea;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
}

.art-catalog-card:hover {
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgb(0 0 21 / 25%) !important;
  border-color: transparent;
  transition: 0.3s;
  transition-timing-function: ease-in-out;
}

.card-body {
  text-align: center;
}

img.fixed-image-size {
  border-radius: 4px;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
}
.art-catalog-card:hover img.fixed-image-size {
  transform: scale(1.1);
  transition: 0.3s;
  transition-timing-function: ease-in-out;
}

.nav-link-art-catalog {
  border: 0px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link-art-catalog {
  color: darkgray !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link-art-catalog.active {
  background-color: #fff;
  color: black !important;
  border-bottom: 1px solid #fff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

.setup-story-nav-links-tabs {
  border-bottom: 0px solid #fff;
}

ul.nav.nav-tabs.table-width.dashboard.art-catalog-tabs-container
  > li
  > a.active.nav-link {
  color: #768192 !important;
}

ul.nav.nav-tabs.table-width.dashboard.art-catalog-tabs-container > li > a {
  font-size: 15px;
  color: #481fca !important;
  padding-bottom: 10px;
  font-weight: 600;
  font-family: "Nunito";
}

ul.nav.nav-tabs.table-width.dashboard.setup-story-nav-links-tabs
  > li
  > a.active.nav-link {
  color: #768192 !important;
}

ul.nav.nav-tabs.table-width.dashboard.setup-story-nav-links-tabs > li > a {
  font-size: 15px;
  color: #481fca !important;
  padding-bottom: 10px;
  font-weight: 600;
  font-family: "Nunito";
}

.form-filter-top-art-catalog {
  font-family: "Nunito";
  font-size: 15px;
}

.div-input-row {
  height: 25px;
}

.radio-button {
  width: 15px;

  margin-top: 3px;
  margin-left: 6px;
}

.art-catalog-serach {
  height: 30px;
}

.art-catalog-serach-set2-cont {
  margin-top: 20px;
  margin-left: 50px;
  /*border: 1px solid #f1f1f1; */
  background-color: #f5f5f5;
  padding: 7px 15px 4px 15px;
  border-radius: 4px;
}

.art-catalog-serach-set1-cont {
  margin-top: 20px;
  background-color: #f5f5f5;
  padding: 7px 15px 4px 15px;
  border-radius: 4px;
}

.first-search {
  padding-left: 0px;
}
/*********************/

/*setup story*/

.notes-setup-story {
  justify-content: space-between;
}
.setup-story-desciption {
  padding-left: 0px;
  padding-right: 0px;
  width: 32%;
  min-height: 10%;
}

.setup-story-transl-notes {
  padding-left: 0px;
  padding-right: 0px;
  width: 32%;
  min-height: 10%;
}

.setup-story-mark-notes {
  padding-left: 0px;
  padding-right: 0px;
  width: 32%;
  min-height: 10%;
}

.story-img-container {
  float: left;
  width: auto;
}

.info-form-container {
  /*margin-left:220px; */
}
.story-image-cont {
  background-color: #f1f1f1;
  height: 244px;
  width: 10vw;
  background-image: url("https://upload.wikimedia.org/wikipedia/en/b/b4/William_shakespeares_romeo_and_juliet_movie_poster.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  margin-right: 20px;
}

.setup-story-add-button-cont {
  padding: 10px 0px 15px 0px;
  background-color: #fff;
}

.setup-story-title {
  width: 31vw;
  margin-right: 15px;
}

.setup-story-author {
  width: 13vw;
}

.text-ar-info {
  height: 10vh !important;
}

.setup-story-info-top {
  margin-bottom: 10px;
  padding-bottom: 10px;
  align-content: flex-start;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.setup-story-genre
{
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  align-content: flex-start;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.padding-remove
{
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.status-select,
.release-date-setup-story {
  min-width: 193px;
}

textarea:focus::placeholder {
  color: transparent;
}

select.select-progress > option:nth-child(1) {
  color: #ffb800;
  font-weight: 500;
}

select.select-progress > option:nth-child(2) {
  color: #19af3d;
  font-weight: 500;
}

.author-name-setup-story > div,
.author-name-setup-story- > div {
  color: #481fca;
  font-weight: 500;
}

.add-icon-setup-story,
.icon-primary-btn {
  margin-top: -2px;
}

.field-setup-story-bold {
  font-weight: 500 !important;
}

.label-setup-story {
  font-weight: 500 !important;
}

label.label-info-box {
  font-weight: 500 !important;
  width: max-content;
}

.lbl-title {
  display: none;
}

.label {
  font-weight: 600;
}

.action-col-title {
  color: #481fca;
}
/**********************/
/*translate*/
table.download-all-btn-table {
  width: -webkit-fill-available;
}

/*table.download-all-btn-table > tbody > tr > td {
    width: 20% !important;

} */

table.download-all-btn-table > tbody > tr {
  border: 0px !important;
}

table.download-all-btn-table > tbody > tr > td:nth-child(1),
table.download-all-btn-table > tbody > tr > td:nth-child(2),
table.translations-translate-table > thead > tr > th:nth-child(1),
table.translations-translate-table > thead > tr > th:nth-child(2) {
  width: auto;
}

table.translations-translate-table > thead > tr > th:nth-child(5),
table.translations-translate-table > thead > tr > th:nth-child(4),
table.translations-translate-table > thead > tr > th:nth-child(3),
table.download-all-btn-table > tbody > tr > td:nth-child(3) {
  width: 12%;
  text-align: center;
  padding-right: 12px !important;
}

table.download-all-btn-table > tbody > tr > td:nth-child(4),
table.download-all-btn-table > tbody > tr > td:nth-child(5) {
  width: 12%;
  text-align: center;
}

table.translations-translate-table > tbody > tr > td:nth-child(5) > div,
table.translations-translate-table > tbody > tr > td:nth-child(4) > div,
table.translations-translate-table > tbody > tr > td:nth-child(3) > div {
  margin-left: auto;
  margin-right: auto;
  width: min-content;
}

.download-all-btns-cont {
  padding-right: 0px;
}
.download-all-btns-cont > .row-style {
  float: right;
}

.btn-dwn-sr-en {
  margin-top: 28px;
  text-align: right;
}

.btn-fin-trans {
  margin-top: 27px;
  text-align: right;
}
/**********************/
/*buttons*/
.btn-primary-sm {
  color: #fff;
  background-color: #481fca;
  border-color: #481fca;
  font-family: "Nunito";
  font-size: 15px;
}

.btn-primary-sm.disabled,
.btn-primary-sm:disabled {
  color: #fff;
  background-color: #481fca;
  border-color: #481fca;
  font-family: "Nunito";
}
.btn-primary-sm:hover {
  background-color: #745dec;
  border-color: #745dec;
  color: #fff;
}

.btn-primary-outline-sm {
  color: #481fca;
  background-color: #fff;
  border-color: #481fca;
  font-family: "Nunito";
  font-size: 15px;
}

.btn-primary-outline-sm:hover {
  background-color: #745dec;
  border-color: #745dec;
  color: #fff;
}

.btn-cancel-sm {
  color: #481fca;
  background-color: transparent;
  border-color: transparent;
  font-family: "Nunito";
  font-size: 15px;
}

.btn-cancel-sm.disabled,
.btn-cancel-sm:disabled {
  color: #fff;
  background-color: #481fca;
  border-color: #481fca;
}

.btn-cancel-sm:hover {
  color: #745dec;
  /*background-color:#f1f1f1;
            border-color:#f1f1f1; */
}
.user-menu-button {
  background-color: transparent !important;
  color: #fff !important;
  border: none !important;
  margin-right: 30px;
}

.user-menu-button:hover {
  /*text-decoration:underline!important; */
  opacity: 0.7 !important;
}

.user-menu-button:focus {
  box-shadow: none !important;
}

.user-menu-button:after {
  margin-bottom: -2px;
  margin-left: 10px !important;
}

.wide {
  padding-left: 20px;
  padding-right: 20px;
}

/*create story*/

.button-create {
  background-color: #ffb800 !important;
  border-color: #ffb800 !important;
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 500;
  padding-left: 20px !important;
  padding-right: 20px !important;
  /*box-shadow: 0 16px 26px -10px rgb(63 106 216 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(63 106 216 / 20%);*/
  box-shadow: 0 0.125rem 0.625rem rgb(160 116 1 / 40%),
    0 0.0625rem 0.125rem rgb(160 116 1 / 50%);
  border-radius: 0.3rem !important;
  font-family: "Nunito";
}

.button-create:hover {
  /*box-shadow: 0 16px 16px -12px rgb(63 106 216 / 56%), 0 4px 15px 0px rgb(0 0 0 / 12%), 0 8px 5px -5px rgb(63 106 216 / 20%);*/
  border-color: #d69a00 !important;
  background-color: #d69a00 !important;
}

.button-create svg {
  margin-top: -3px;
}

.author-name-setup-story- {
  width: max-content;
}

.setup-story-desciption-,
.setup-story-transl-notes-,
.setup-story-mark-notes- {
  margin-bottom: 15px;
}

.modal-row-stp-story.col-sm-12.row,
.form-group-name.crt-story {
  /*padding-right: 0px;*/
  margin-bottom: 0px !important;
}

.crt-stry-12 {
  /*padding-right:0px;*/
}

.modal-row-stp-story.col-sm-12.row > div > label,
.modal-row-stp-story.col-sm-12.row > div > div > label {
  margin-top: 15px;
}

.crt-story.char {
  padding-left: 0px;
}

/*edit episode*/

.select-width-new {
  min-width: 81px;
}

.select-width-new-line {
  min-width: 150px !important;
}

.top-row-n {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.margin-left-form-minus {
  margin-left: -15px;
  margin-right: 8px;
}

.oline-inputgroup {
  margin-left: -11px;
}
/*responsive*/
@media only screen and (max-width: 576px) {
  .top-bar {
    width: -webkit-fill-available !important;
  }
  .stories-table-margin,
  .register-signin-left-panel.col-sm-12.rounded.shadow,
  .register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread,
  .register-signin-left-panel.col-sm-12.rounded.shadow.translation-proofread-tables {
    margin-left: 0 !important;
    padding: 10px 25px 25px 20px !important;
  }

  .btn-fin-trans,
  .trans-btn-cont {
    text-align: left;
  }

  .trans-btn-cont {
    margin-left: 0px !important;
    padding-left: 0px;
  }

  .user-menu-button {
    margin-right: 0px;
  }

  .table-proofread-title {
    font-family: "Nunito";
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .episode-proofread,
  .story-proofread,
  .find-proofread,
  .btn-proofread,
  .trans-story-cont,
  .trans-episode-cont,
  .trans-type-cont {
    padding-left: 0px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 0px !important;
  }

  table.download-all-btn-table > tbody > tr > td:nth-child(1),
  table.download-all-btn-table > tbody > tr > td:nth-child(2),
  table.download-all-btn-table > tbody > tr > td:nth-child(3) {
    display: none;
  }

  table.download-all-btn-table > tbody > tr > td:nth-child(4),
  table.download-all-btn-table > tbody > tr > td:nth-child(5) {
    padding-top: 15px;
    text-align: center;
  }

  /*stories 576*/

  .button-width {
    width: 60%;
  }

  .stp-story,
  .add-episode {
    font-size: 14px;
    text-align: left;
  }

  .stp-story > svg,
  .add-episode > svg {
    display: none;
  }

  .stories-bottom-cont {
    width: 100% !important;
  }
  .crt-story.lang {
    margin-top: 15px;
  }

  .crt-story.char {
    padding-left: 0px;
  }

  .from-status-select {
    margin-right: 0px !important;
  }
  /*art catalog 576*/
  .nav-tabs .nav-link {
    /*border: 1px solid transparent; */
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem !important;
  }
  .radio-filters {
    flex-direction: column;
  }
  .art-catalog-serach-set1-cont,
  .art-catalog-serach-set2-cont {
    flex-direction: column;
  }

  .art-catalog-serach-set2-cont {
    margin-left: 0px;
  }

  .art-catalog-serach-set2-cont > .div-input-row.row-style.ml-4,
  .art-catalog-serach-set1-cont > .div-input-row.row-style.ml-4 {
    margin-left: 0px;
  }

  .row-style.art-catalog-fields-cont {
    flex-direction: column;
  }

  .art-catalog-fields-cont > .form-group-name {
    width: auto;
    margin-bottom: 10px;
  }

  .tab-pane.active.tab-pan-art-catalog.fade.show {
    padding: 20px 20px 20px 20px;
  }

  .tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div > div {
    margin-left: 0px !important;
  }

  .form-group.art-catalog-serach-set2-cont.row-style.ml-6 {
    margin-top: 0px;
  }

  .row-style.crt-stry-12.modal-row.col-sm-12.row {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .setup-story-title- {
    margin-bottom: 15px;
  }

  .trans-story-cont > label,
  .trans-episode-cont > label,
  .trans-type-cont > label {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 600px) {
  .nav-tabs .nav-link {
    /*border: 1px solid transparent; */
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem !important;
  }

  .stories-table-margin {
    margin-left: 0;
    padding: 10px 25px 25px 20px !important;
  }

  .episode-proofread,
  .story-proofread,
  .find-proofread,
  .btn-proofread,
  .trans-story-cont,
  .trans-episode-cont,
  .trans-type-cont {
    padding-left: 0px;
    margin-bottom: 5px;
    margin-left: 0px !important;
    /*margin-right:10px; */
  }

  .btn-dwn-sr-en {
    padding-left: 0px;
    margin-left: 0px;
  }

  .btn-fin-trans-btn,
  .btn-down-sr-en {
    width: max-content;
  }

  .find-text-input {
    width: 34vw;
  }

  .btn-fin-trans {
    display: inline-table;
  }

  .top-search-translate {
    display: block;
  }
  .trans-story-cont,
  .trans-episode-cont,
  .trans-type-cont {
    display: contents;
    padding-left: 0px;
    margin-bottom: 5px;
    margin-left: 0px !important;
  }

  .trans-story-cont > label,
  .trans-episode-cont > label,
  .trans-type-cont > label {
    margin-top: 10px;
  }

  table.download-all-btn-table > tbody > tr > td:nth-child(1),
  table.download-all-btn-table > tbody > tr > td:nth-child(2),
  table.download-all-btn-table > tbody > tr > td:nth-child(3) {
    display: none;
  }

  /*stories 600*/
  .button-width {
    width: 60%;
  }
  .stories-bottom-cont {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .radio-filters {
    flex-direction: column;
  }

  .art-catalog-serach-set2-cont {
    /*margin-left: 0px; */
  }

  .form-group.art-catalog-serach-set2-cont.row-style.ml-6 {
    /*margin-top: 0px; */
  }

  .tab-pane.active.tab-pan-art-catalog.fade.show {
    padding: 20px 20px 20px 20px;
  }
}

@media only screen and (min-width: 768px) {

    /* translate lines */
    div.c-line-trns-clth > input, div.c-line-trns-hair > input {
        flex-grow: 0 !important;
        width: 1vw;
    }
    /*/*/
    /** modal dialog **/
    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div > div > div.column {
        width: 50%;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div.modal-dialog {
        min-width: 1200px !important;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div.modal-content {
        width: 100% !important;
    }

    div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block {
        width: 100vw !important;
        left: 0 !important;
    }
    /* C, O lines fix */
    .c-line-clh, .hair-line, .c-line-trns {
        justify-content: flex-start !important;
        align-items: center !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
    }

    .o-linija > span.col-sm-1, .input-group.oline-inputgroup > span.col.cursor {
        justify-content: flex-start !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 100% !important;
    }

    .o-linija > .col {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 1% !important;
    }

  .stories-table-margin {
    margin-left: 0;
    padding: 10px 25px 25px 20px !important;
  }

  .art-catalog-serach-set2-cont {
    margin-top: 0px;
    margin-left: 0px;
    /*border: 1px solid #f1f1f1; */
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .art-catalog-serach-set1-cont {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .radio-filters {
    /*flex-direction: row; */
  }

  .button-width {
    width: 30%;
  }
}

@media only screen and (min-device-width: 1024px) {

    .b-line-select {
        max-width: 60vw;
    }

    /* translate lines */
    div.c-line-trns-clth > input, div.c-line-trns-hair > input {
        flex-grow: 0 !important;
        width: 1vw;
    }
    /*/*/
    /** modal dialog **/
    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div > div > div.column {
        width: 50%;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div.modal-dialog {
        min-width: 85vw !important;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div.modal-content {
        width: 100% !important;
    }

    div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block {
        width: 100vw !important;
        left: 0 !important;
    }
    /* C, O lines fix */
    .c-line-clh, .hair-line, .c-line-trns {
        justify-content: flex-start !important;
        align-items: center !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
    }

    .o-linija > span.col-sm-1, .input-group.oline-inputgroup > span.col.cursor {
        justify-content: flex-start !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 100% !important;
    }

    .o-linija > .col {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 1% !important;
    }

  .edit-episode-header {
    flex-wrap: inherit;
  }

  .row-style.row.ml-2.top-search-translate {
    flex-wrap: inherit;
    display: flex;
  }

  .find-text-input {
    width: -webkit-fill-available;
  }

  .trans-story-cont > label,
  .trans-episode-cont > label,
  .trans-type-cont > label {
    margin-top: 0px;
  }

  .trans-story-cont,
  .trans-episode-cont,
  .trans-type-cont {
    display: block;
  }

  table.download-all-btn-table > tbody > tr > td:nth-child(1),
  table.download-all-btn-table > tbody > tr > td:nth-child(2),
  table.download-all-btn-table > tbody > tr > td:nth-child(3) {
    display: table-cell;
  }

  .stories-table-margin {
    margin-left: 26%;
  }

  .stories-table-margin-minimized {
    margin-left: 3% !important;
    padding: 15px 25px 50px 50px !important;
  }

  .art-catalog-serach-set2-cont {
    margin-top: 20px;
    margin-left: 50px;
    /*border: 1px solid #f1f1f1; */
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .art-catalog-serach-set1-cont {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .radio-filters {
    flex-direction: row;
  }

  .button-width {
    width: 27%;
  }

    .editEpisodeWrapper > div > fieldset > div > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.add-new-line-modal.show.d-block > div.modal-dialog {
        width: 85vw !important;
        min-width: 85vw !important;
    }
}

@media only screen and (min-device-width: 1200px) {

    .b-line-select {
        max-width: 60vw;
    }

      

        div.c-line-trns-clth > input, div.c-line-trns-hair > input {
            flex-grow: 0 !important;
            width: 1vw;
        }
    /** modal dialog **/

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div > div > div.column {
        width: 50%;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div.modal-dialog {
        min-width: 85vw !important;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div.modal-content {
        width: 100% !important;
    }

    div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block {
        width: 100vw !important;
        left: 0 !important;
    }
    /* C, O lines fix */
    .c-line-clh, .hair-line, .c-line-trns {
        justify-content: flex-start !important;
        align-items: center !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
    }

    .o-linija > span.col-sm-1, .input-group.oline-inputgroup > span.col.cursor {
        justify-content: flex-start !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 100% !important;
    }

    .o-linija > .col {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 1% !important;
    }

  .stories-table-margin {
    margin-left: 22%;
  }

    .editEpisodeWrapper > div > fieldset > div > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.add-new-line-modal.show.d-block > div.modal-dialog {
        width: 85vw !important;
        min-width: 85vw !important;
    }
}

@media only screen and (min-device-width: 1336px) {

    .b-line-select {
        max-width: 60vw;
    }

    div.c-line-trns-clth > input, div.c-line-trns-hair > input {
        flex-grow: 0 !important;
        width: 1vw;
    }
    /** modal dialog **/

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div > div > div.column {
        width: 50%;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div.modal-dialog {
        min-width: 1200px !important;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div.modal-content {
        width: 100% !important;
    }

    div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block {
        width: 100vw !important;
        left: 0 !important;
    }
    /* C, O lines fix */
    .c-line-clh, .hair-line, .c-line-trns {
        justify-content: flex-start !important;
        align-items: center !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
    }

    .o-linija > span.col-sm-1, .input-group.oline-inputgroup > span.col.cursor {
        justify-content: flex-start !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 100% !important;
    }

    .o-linija > .col {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 1% !important;
    }


  .stories-table-margin {
    margin-left: 19%;

  }
    .editEpisodeWrapper > div > fieldset > div > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.add-new-line-modal.show.d-block > div.modal-dialog {
        width: 85vw !important;
        min-width: 85vw !important;
    }
}

@media only screen and (min-device-width: 1440px) {

    /* translate lines */
    div.c-line-trns-clth > input, div.c-line-trns-hair > input {
        flex-grow: 0 !important;
        width: 1vw;
    }
    /*/*/
    /** modal dialog **/
    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div > div > div.column {
        width: 50%;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div.modal-dialog {
        min-width: 1200px !important;
    }

    div.tab-content.table-width.dashboard.art-catalog-tabs-container > div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block > div > div.modal-content {
        width:100%!important;
    }

    div.tab-pane.active.tab-pan-art-catalog.fade.show > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.show.d-block {
        width: 100vw!important;
        left:0!important;
    }
    /* C, O lines fix */
    .c-line-clh, .hair-line, .c-line-trns {
        justify-content: flex-start !important;
        align-items: center !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
    }

    .o-linija > span.col-sm-1, .input-group.oline-inputgroup > span.col.cursor {
        justify-content: flex-start !important;
        font-size: 11px !important;
        overflow: hidden;
        padding-left: 2px !important;
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 100% !important;
    }

    .o-linija > .col {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        min-width: 0 !important;
        max-width: 1% !important;
    }

    

   
        .stories-table-margin {
            margin-left: 18%;
            padding: 10px 25px 25px 20px !important;
        }

  .stories-table-margin-minimized {
    margin-left: 2% !important;
    padding: 15px 25px 50px 50px !important;
    margin-right: 0;
  }

  .art-catalog-serach-set2-cont {
    margin-top: 20px;
    margin-left: 50px;
    /*border: 1px solid #f1f1f1; */
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .art-catalog-serach-set1-cont {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .radio-filters {
    flex-direction: row;
  }

  .button-width {
    width: 20%;
  }

    .editEpisodeWrapper > div > fieldset > div > div > div > div.modal.overflow-auto.fade.popup-modal.remove-width.add-new-line-modal.show.d-block > div.modal-dialog {
        width: 85vw !important;
        min-width: 85vw !important;
    }
}

@media only screen and (min-device-width: 1920px) {
  .stories-table-margin {
    margin-left: 14%;
    padding: 10px 25px 25px 20px !important;
  }

  .art-catalog-serach-set2-cont {
    margin-top: 20px;
    margin-left: 50px;
    /*border: 1px solid #f1f1f1; */
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .art-catalog-serach-set1-cont {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .radio-filters {
    flex-direction: row;
  }
}

@media only screen and (min-device-width: 2560px) {
  .stories-table-margin {
    margin-left: 11%;
    margin-right: 1%;
  }

  .stories-table-margin-minimized {
    margin-left: 2% !important;
    padding: 15px 40px 50px 50px !important;
    margin-right: 0;
  }

  .art-catalog-serach-set2-cont {
    margin-top: 20px;
    margin-left: 50px;
    /*border: 1px solid #f1f1f1; */
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .art-catalog-serach-set1-cont {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .radio-filters {
    flex-direction: row;
  }

  .button-width {
    width: 10%;
  }
}

@media only screen and (min-device-width: 3840px) {
  .stories-table-margin {
    margin-left: 7%;
  }

  .stories-table-margin-minimized {
    margin-left: 1% !important;
  }
  .art-catalog-serach-set2-cont {
    margin-top: 20px;
    margin-left: 50px;
    /*border: 1px solid #f1f1f1; */
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .art-catalog-serach-set1-cont {
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 7px 15px 4px 15px;
    border-radius: 4px;
  }

  .radio-filters {
    flex-direction: row;
  }
}

.create-new-story-modal-popup .modal-dialog {
  max-width: 800px !important;
  margin: 5.75rem auto;
  padding-top: 10%;
  min-width: 1500px !important;
  padding-left: 40px;
}
.create-new-story-modal-popup .modal-content {
  height: 0px !important;
}

.create-new-story-modal-popup .modal-dialog .modal-content .modal-header {
  background-color: #ffb800;
  color: #fff;
  padding: 15px 20px 15px 20px;
}

div.modal.overflow-auto.fade.create-new-story-modal-popup.show.d-block
  > div
  > div
  > header {
  background-color: #ffb800;
}

.create-new-story-modal-popup
  .modal-dialog
  .modal-content
  .modal-header
  .close {
  padding: 18px 20px 13px 20px;
  opacity: 1;
  text-shadow: none;
  color: white;
}

.create-new-story-modal-popup
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title {
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
}

.create-new-story-modal-popup
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title
  svg {
  margin-top: -3px;
}

.create-new-story-modal-popup .modal-dialog .modal-content {
  border: 0px !important;
}

.create-new-story-modal-popup .modal-dialog .modal-content .modal-body {
  padding: 40px 30px 40px 30px;
}

.fixed-image-size {
  height: 200px;
  width: 200px;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
  margin: 40px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: rgb(255, 255, 255);
  color: black;
}

.p-bold {
  font-weight: 600;
}

.flip-card-back {
  background-color: #745dec;
  color: white;
  transform: rotateY(180deg);
  padding: 15px;
  justify-content: flex-start;
  border-radius: 4px;
}

.flip-card-back > h6 {
  font-weight: 600;
  font-size: 11px;
  border-bottom: 1px solid #fff;
  margin-top: 0px !important;
  padding-bottom: 10px;
}
.flip-card-back > p {
  margin-bottom: 3px;

  font-size: 11px;
  width: 100%;
  text-align: left;
}
/*edit story lines*/
.action-div-cont {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  /*border-left:1px solid #ccc; */
  height: 25px;
  padding-right: 25px;
  background-color: #6a619814;
  /*background-color: #f1f1f1; */
}

.action-div-cont > span > svg,
.action-div-cont > span > button > svg {
  height: 19px;
  width: 19px;
  margin-bottom: 2px;
}

.action-icon.minus > svg {
  margin-bottom: 0px;
}

.action-div-cont > span > button {
  padding-bottom: 0px;
  padding-top: 0px;
}

.input-group.oline-inputgroup > span {
  font-size: 12px;
  height: 25px !important;
}

#add-line-form-submit > div > span,
#add-line-form-submit > div > input,
.margin-left-form-minus > div > span,
.margin-left-form-minus > div > input,
.input-group.oline-inputgroup > span,
.input-group.oline-inputgroup > input,
#add-line-form-submit > div > select,
form.row > div > span,
form.row > div > input {
  font-size: 12px;
  height: 25px !important;
  min-height: 25px !important;
}

#add-line-form-submit,
.margin-left-form-minus,
.input-group.oline-inputgroup,
.empty-line-edit {
  height: 25px !important;
}

div.column-style.mt-3.ml-1 > div > form > div.input-group.col > input {
  min-width: 60px !important;
}

#add-line-form-submit > div.input-group.col.border-input-line > input,
#add-line-form-submit > div.input-group.col.border-input-line > span,
#add-line-form-submit > div.input-group.col.border-input-line > select,
form.margin-left-form-minus
  > div.input-group
  > input.col.input-field-cinematic-gray,
form.margin-left-form-minus > div.input-group > input.col-auto,
div.column-style.mt-3.ml-1
  > div
  > form
  > div.input-group.col
  > input.col.input-field-cinematic-gray,
div.column-style.mt-3.ml-1 > div > form > div.input-group.col > input,
div.column-style.mt-3.ml-1 > div > form.row > div.col-auto.action-div-cont,
div.column-style.mt-3.ml-1
  > div
  > form.row
  > span.action-icon
  plus
  > div.col-auto.action-div-cont,
#add-line-form-submit > div.input-group.col > select {
  height: 38px !important;
  min-height: 38px !important;
  font-size: 14px !important;
  min-width: 60px;
}

div.column-style.mt-3.ml-1
  > div
  > #add-line-form-submit
  > div:nth-child(3)
  > span.action-icon.plus
  > button
  > svg,
div.column-style.mt-3.ml-1
  > div
  > form
  > div.input-group.col
  > div.col-auto.action-div-cont
  > span.action-icon.plus
  > button
  > svg {
  width: 21px;
  height: 21px;
  margin-top: 7px;
}

div.column-style.mt-3.ml-1 > div > form > div > div.col-auto.action-div-cont {
  height: 38px;
}
/* row hover */
.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode
  > div
  > div
  > form:hover,
.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode
  > div
  > div
  > div.input-group.oline-inputgroup:hover {
  background-color: #f1f1f1;
  box-shadow: -15px 0px 0px 0px #481fca, 15px 0px 0px 0px #f1f1f1;
}

.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode
  > div
  > div
  > div.input-group.oline-inputgroup:hover {
  background-color: #f1f1f1;
  box-shadow: -15px 0px 0px 0px #481fca, 40px 0px 0px 0px #f1f1f1;
}

.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode
  > div
  > div
  > div.input-group.oline-inputgroup:hover
  > .col-auto.action-div-cont {
  background-color: #fff !important;
  box-shadow: 0px 0px 0px 0px #481fca, 10px 0px 0px 0px #481fca;
  border-top: 1px solid rgba(118, 118, 118, 0.3);
  border-bottom: 1px solid rgba(118, 118, 118, 0.3);
}

#add-line-form-submit:hover > .col-auto.action-div-cont,
form:hover > .col-auto.action-div-cont,
div.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode
  > div
  > div
  > form:hover
  > div
  > .col-auto.action-div-cont {
  background-color: #fff !important;
  box-shadow: 0px 0px 0px 0px #481fca, 10px 0px 0px 0px #481fca;
  border-top: 1px solid rgba(118, 118, 118, 0.3);
  border-bottom: 1px solid rgba(118, 118, 118, 0.3);
}
/*action icons */
.action-icon.minus > svg,
.action-icon.plus > button > svg,
.action-icon.capa > svg,
.action-icon.arrow-left > svg,
div.column-style.mt-3.ml-1
  > div
  > form.row
  > div.col-auto.action-div-cont
  > button
  > svg {
  color: #481fca;
}

.action-icon.plus > button > svg:hover,
.action-icon.capa > svg:hover {
  color: #745deca6;
}

/*action icons colors*/

/*hover INPUT line bottom*/
div.column-style.mt-3.ml-1 > div > form > div > div.col-auto.action-div-cont,
div.column-style.mt-3.ml-1 > div > form > div.col-auto.action-div-cont {
  background-color: #fff;
  box-shadow: none !important;
  border-top: 0px solid rgba(118, 118, 118, 0.3) !important;
  border-bottom: 0px solid rgba(118, 118, 118, 0.3) !important;
}

.action-icon.minus > svg {
  color: red;
}

.action-icon.plus > button > svg,
div.column-style.mt-3.ml-1 > div > #add-line-form-submit > div > button > svg,
div.column-style.mt-3.ml-1 > div > form > div > button > svg {
  color: green !important;
}

.action-icon.arrow-left > svg {
  color: green;
}
.action-icon.arrow-left > svg:hover,
.action-icon.minus > svg:hover,
.action-icon.plus > button:hover,
.action-icon.diamond > svg:hover,
div.column-style.mt-3.ml-1
  > div
  > form
  > div.col-auto.action-div-cont
  > button:hover {
  opacity: 0.6 !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.action-button-margin {
  margin-top: 60% !important;
}

.disable-comment-click {
  pointer-events: none;
}
/*lines arrow navigation up/down*/

.column-style.mt-3.ml-1 > div > #add-line-form-submit .input-group.col > span,
.column-style.mt-3.ml-1 > div > #add-line-form-submit .input-group.col > input,
.column-style.mt-3.ml-1 > div > div > span,
.column-style.mt-3.ml-1 > div > div > input {
  height: 38px !important;
  font-size: 14px;
}

div.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode
  > div
  > div.column-style.mt-3.ml-1 {
  height: 38px !important;

  border-left: 0px;
}

.column-style.mt-3.ml-1
  > div
  > div.input-group.oline-inputgroup.oline-inputgroup-border
  > .col-auto.action-div-cont {
  background-color: white;
}

.column-style.mt-3.ml-1
  > div
  > div.input-group.oline-inputgroup.oline-inputgroup-border {
  margin-left: -15px;
}

.column-style.mt-3.ml-1 > div > #add-line-form-submit .input-group.col {
  padding-left: 0px !important;
}

/*O linija left blue border hover fix*/

.input-group.oline-inputgroup.o-linija {
  width: auto !important;
  margin-left: -15px;
  padding-left: 4px !important;
  padding-right: 11px;
}

div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > #add-line-form-submit
  > div.input-group.col {
  margin-left: 0px !important;
}

/*visina container boxa kada se menjaju linije preko arrow keys - fix*/
.input-group {
  margin-bottom: 0px !important;
}

/*google login page*/
.main-login {
  /*background-color: #745DEC !important; */
}

.sign.center {
  /*color: #FFB800; */
}

.main-login {
  /*background-image:url("styles/back.jpg"); */
  background-position: center bottom;
  background-size: cover;
}

/*ArtCatalog - audio player styling*/

audio {
  width: 220px;
  margin-top: 33%;
}

audio::after {
  content: "Test bre";

  width: 100px;
  height: 100px;
  background-color: rebeccapurple;
}
audio::-webkit-media-controls-enclosure {
  width: 100%;
  max-width: 800px;
  height: 55px;
  flex-shrink: 0;
  bottom: 0;
  text-indent: 0;
  padding: 0;
  box-sizing: border-box;
}

audio::-webkit-media-controls-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* We use flex-start here to ensure that the play button is visible even
     * if we are too small to show all controls.
     */
  justify-content: flex-start;
  -webkit-user-select: none;
  position: relative;
  /*width: 90%;*/
  z-index: 0;
  overflow: hidden;
  text-align: right;
  bottom: auto;
  height: 55px;
  border-radius: 5px;
  /* The duration is also specified in MediaControlElements.cpp and LayoutTests/media/media-controls.js */
  transition: opacity 0.3s;
}
.circle-for-none
{
  width: 15px;
  height: 15px;
  background-color: #f85f9a;
  border-radius: 5px;
  margin-top: 2.5px;
}
.circle-for-shortsound
{
  width: 15px;
  height: 15px;
  background-color: #745dec;
  border-radius: 5px;
  margin-top: 2.5px;
}
.circle-for-music
{
  width: 15px;
  height: 15px;
  background-color: #e8b0f6;
  border-radius: 5px;
  margin-top: 2.5px;
}
audio.shortsound::-webkit-media-controls-panel 
{
  background-color: #745dec;
}
audio.none::-webkit-media-controls-panel 
{
  background-color: #f85f9a;
}
audio.music::-webkit-media-controls-panel 
{
  background-color: #e8b0f6;
}

audio:-webkit-full-page-media,
video:-webkit-full-page-media {
  max-height: 100%;
  max-width: 100%;
}

audio:-webkit-full-page-media::-webkit-media-controls-panel,
video:-webkit-full-page-media::-webkit-media-controls-panel {
  bottom: 0px;
}

audio::-webkit-media-controls-mute-button,
video::-webkit-media-controls-mute-button {
  -webkit-appearance: media-mute-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 15px;
  height: 30px;
  line-height: 30px;
  margin: 0 0px 0 0;
  padding: 0;
  background-color: initial;
  color: inherit;
}

audio::-webkit-media-controls-overlay-enclosure {
  display: none;
}

audio::-webkit-media-controls-play-button,
video::-webkit-media-controls-play-button {
  -webkit-appearance: media-play-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  background-color: initial;
  color: inherit;
}

audio::-webkit-media-controls-timeline-container,
video::-webkit-media-controls-timeline-container {
  -webkit-appearance: media-controls-background;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1;
  -webkit-user-select: none;
  height: 16px;
  min-width: 0;
}

audio::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-time-remaining-display {
  -webkit-appearance: media-current-time-display;
  -webkit-user-select: none;
  flex: none;
  display: flex;
  border: none;
  cursor: default;
  height: 30px;
  margin: 0 5px 0 0;
  padding: 0;
  line-height: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  /*color: #FFB800; */
  color: white;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-decoration: none;
}

audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  -webkit-appearance: media-slider;
  display: flex;
  flex: 1 1 auto;
  height: 8px;
  margin: 0 15px 0 0;
  padding: 0;
  background-color: transparent;
  min-width: 25px;
  border: initial;
  color: inherit;
}

audio::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-volume-slider {
  -webkit-appearance: media-volume-slider;
  display: flex;
  /* The 1.9 value was empirically chosen to match old-flexbox behaviour
     * and be aesthetically pleasing.
     */
  flex: 1 1.9 auto;
  height: 8px;
  max-width: 70px;
  margin: 0 15px 0 0;
  padding: 0;
  background-color: transparent;
  min-width: 15px;
  border: initial;
  color: inherit;
}

/*background style audio player*/
.audio-player-container {
  width: fit-content;
  height: inherit;
  background-image: url("styles/musicNote1.png");
  background-position: 1000px 1000px;
  background-size: 300px 300px;
  background-repeat: no-repeat;
  border: solid 0px #fff;
  transition: border 0.2s ease-in-out;
}

.audio-player-container:hover {
  width: fit-content;
  height: inherit;
  background-image: url("styles/musicNote1.png");
  background-position: -40px -54px;
  background-size: 280px 280px;
  background-repeat: no-repeat;
  border-top-width: 10px;
  border-bottom-width: 5px;
  border-radius: 4px;
}

.filtered-color {
  color: #481fca;
  font-weight: bold;
}

.comment-unresolved-text {
  color: gray;
  font-size: medium;
}

.legend-actions {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: 0 10px;
  background: white;
  width: 750px;
}

.legend-body {
  display: flex;
  background: white;
  width: 1000px;
  justify-content: center;
  align-items: center;
  margin-left: 330px;
}

.legend-body.nt-info,
.nt-info.legend-actions {
  margin-left: 0px !important;
  padding-bottom: 10px;
  padding-top: 10px;
  z-index: 50;
}
/*add new line modal*/
.remove-width.add-new-line-modal {
  width: auto !important;
  left: 0 !important;
  right: 0 !important;
  /*max-width:1440px!important; */
  width: 100vw !important;
  /*max-width: unset !important; */
}

.remove-width.add-new-line-modal > .modal-dialog {
  min-width: 1400px !important;
}

.remove-width.add-new-line-modal
  > .modal-dialog
  > .modal-content
  > .modal-footer {
  border-top: 0px !important;
}

.remove-width.add-new-line-modal
  > .modal-dialog
  > .modal-content
  > .modal-body
  > .column-style.mt-3.ml-1
  > div
  > form
  > .col-auto.action-div-cont
  > .action-icon.plus
  > .btn
  > svg {
  width: 21px;
  height: 21px;
}

.remove-width.add-new-line-modal
  > .modal-dialog
  > .modal-content
  > .modal-body
  > .column-style.mt-3.ml-1
  > div
  > form
  > .col-auto.action-div-cont
  > .action-icon.plus
  > .btn {
  padding-top: 6px;
}

.modal-q-add-line {
  font-weight: 600;
  color: #e50914;
}

.modal-press-ent,
.modal-confirm {
  font-weight: 500;
  color: darkgreen !important;
}

.modal-press-ent,
.modal-confirm {
  margin-bottom: 35px;
}

.arrow-down {
  float: right;
  font-size: 20px !important;
}

.input-upload-final {
  width: 84% !important;
  font-size: 12px;
  color: inherit !important;
}

.btn-upload-final {
  background-color: transparent;
  border: 0px !important;
  color: #fff !important;
  font-size: 16px !important;
}

.is-input-translations {
  background-color: #f1f1f1;
  box-shadow: -15px 0px 0px 0px #481fca, 15px 0px 0px 0px #f1f1f1;
}

.o-input-translations {
  background-color: #f1f1f1;
  box-shadow: -15px 0px 0px 0px #481fca, 40px 0px 0px 0px #f1f1f1;
}

.lines-width-frame {
  width: 100px !important;
}

.is-hidden-select {
  visibility: hidden;
}

/*M, G, O linija actions fix*/

/*M linija*/
#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div:nth-child(3) {
  width: 169px !important;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > span.action-icon.plus
  > button,
#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > div
  > span.action-icon.plus
  > button {
  margin-left: 0px !important;
}
/*G linija*/
#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > div:nth-child(5) {
  width: 169px !important;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > div
  > span.action-icon.plus
  > button
  > svg {
  margin-left: 3px;
  margin-top: 6px !important;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > div
  > span.action-icon.plus
  > button
  > svg {
  margin-bottom: 4px;
  margin-left: 3px;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > div
  > span.action-icon.arrow-left.cursor
  > svg {
  margin-bottom: 0px !important;
}
/*O linija*/
div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > #add-line-form-submit
  > .col-auto.action-div-cont {
  width: 169px;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div:nth-child(2)
  > fieldset
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div:nth-child(3)
  > span.action-icon.plus
  > button
  > svg,
#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div:nth-child(2)
  > fieldset
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > div:nth-child(5)
  > span.action-icon.plus
  > button
  > svg,
#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > #add-line-form-submit
  > div.col-auto.action-div-cont
  > span.action-icon.plus
  > button
  > svg {
  width: 25px;
  height: 25px;
}
/*M, C, B linija plus btn*/
#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > .action-icon.arrow-left.cursor {
  margin-right: -12px !important;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div:nth-child(2)
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div.col-auto.action-div-cont {
  padding-top: 8px !important;
}

div.column-style.mt-3.ml-1
  > div
  > #add-line-form-submit
  > div:nth-child(3)
  > span.action-icon.plus
  > button
  > svg {
  margin-top: 0px !important;
}

div.column-style.mt-3.ml-1
  > div
  > form
  > div.input-group.col
  > div.col-auto.action-div-cont
  > span.action-icon.plus
  > button
  > svg {
  margin-top: 7px !important;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div.stories-table-margin-minimized
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > div.col-auto.action-div-cont {
  padding-top: 4px !important;
}

#root
  > div
  > div.app-column.column-style.app-container
  > div
  > div
  > div
  > div.column-style.mt-3.ml-1.editEpisodeWrapper
  > div
  > fieldset
  > div
  > div.column-style.mt-3.ml-1
  > div
  > form
  > div
  > .action-icon.arrow-left.cursor
  > svg {
}

/*translation page*/

.register-signin-left-panel.col-sm-12.rounded.shadow.edit-episode.comments.translations-lines-nt {
  box-shadow: none !important;
  padding: 0px 13px 25px 30px !important;
}

div.translations-lines-nt > div > div > form > div.input-group.col {
  padding-left: 0px;
  margin-left: 4px;
  margin-right: 15px;
}

div.translations-lines-nt > div > div > div.input-group.oline-inputgroup,
div.translations-lines-nt
  > div
  > div.mt-2
  > div
  > div.input-group.oline-inputgroup.o-input-translations {
  margin-left: -15px !important;
}

div.translations-lines-nt
  > div
  > div.mt-2
  > div
  > form.is-input-translations.row
  > div.input-group.col,
div.translations-lines-nt > div > div > div.input-group.oline-inputgroup.row {
  padding-left: 0px !important;
}

div.translations-lines-nt > div > div > form:hover,
div.translations-lines-nt > div > div > div.input-group.oline-inputgroup:hover {
  background-color: #fff !important;
  background: rgb(241, 241, 241);
  background: linear-gradient(
    90deg,
    rgba(241, 241, 241, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  box-shadow: -15px 0px 0px 0px #481fca !important;
}

/*input line*/
div.translations-lines-nt
  > div
  > div.mt-2
  > div
  > form.is-input-translations.row,
div.translations-lines-nt
  > div
  > div.mt-2
  > div
  > div.input-group.oline-inputgroup.o-input-translations {
  background-color: #fff !important;
  background: rgb(241, 241, 241) !important;
  background: linear-gradient(
    90deg,
    rgba(241, 241, 241, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
  box-shadow: -15px 0px 0px 0px #481fca !important;
}

div.translations-lines-nt
  > div
  > div.mt-2
  > div
  > form.is-input-translations.row {
  padding-right: 15px;
}

/*language label*/
h6.lang-label {
  margin-left: -15px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  z-index: 1;
}

.css-2b097c-container {
  z-index: 3;
}

/*tab button*/
.active.nav-link.nav-link-art-catalog {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/*translation header form*/

.translation-header-container {
  border-radius: 4px;
  background-color: #fff;
  padding: 25px 25px 25px 25px;
  box-shadow: 0 0.8rem 1rem rgb(0 0 21 / 15%) !important;
  margin-bottom: 50px;
}

.row-style.row.register-signin-left-panel.top-search-translate {
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
}

/* css fix*/

.p-0.border-fix-input > div {
    border-color: #666 !important;
}

    .p-0.border-fix-input > div > div > div > svg {
        color: #666 !important;
    }
.button-modal-choose-addline-option
{
  background-color: #6868f5;
  color: white;
  width: 250px;
  height: 50px;
}.pagination {
  justify-content: center;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.width-fix-goto-dest
{
  width: 130px;
}

.charming-point-background
{
  border-right-color: darkorange !important;
  border-right-width: 5px !important;
}

.color-select-width
{
  width: 200px !important;
  margin-top: 2%;
}

.setup-story-width-select
{
  width: 240px;
}

.review-blocker
{
  pointer-events: none;
  opacity: 0.8;
  background-color: rgb(236, 236, 236);
}

.review-blocker-diamonds
{
  pointer-events: none;
  opacity: 0.5;
}

.tooltip-comment-exist {
  width:3px;
  border-radius: 2px;
}

.divider {
  height: 2px;
  background-color:rgb(216,219,224);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
  margin-bottom: 15px;
}

/*Actions popup */
.actions-modal
{
  overflow-y: auto !important;
}

.actions-modal .modal-dialog {
  min-width: 85% !important;
  margin-top: 0 !important;
}

.return-to-cr-button
{
  display: flex;
  float: right;
  left: 17%;
}