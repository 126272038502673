.line-options-box
{
    border-right-color: #481fca !important;
    border-right-width: 3px !important;
    max-width: 60px !important;
}

.no-line-options-box
{
    border-right-color: transparent !important;
    border-right-width: 0 !important;
    max-width: 60px !important;
}