 
.new-message-box {
    margin: 110px 0;
    padding-left: 20px;
    margin-bottom: 25px!important;
    width: 450px !important;
}

    .new-message-box p {
        font-size: 1em;
        font-weight: 400;
        margin-top: 8px;
        color: #1a1d1a !important;
    }

.info-tab {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    top: 8px;
}

.info-tab {
    float: left;
    margin-left: -23px;
}

.info-tab i::before {
    width: 24px;
    height: 24px;
    box-shadow: inset 12px 0 13px rgba(0,0,0,0.5);
}

.info-tab i::after {
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-bottom-color: #fff;
    border-left-color: #fff;
    bottom: -18px;
}

/*.info-tab i::before, .info-tab i::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -17px;
    transform: rotateX(60deg);
}*/

.note-box, .warning-box, .tip-box-success, .tip-box-danger, .tip-box-warning, .tip-box-info, .tip-box-alert {
    padding: 12px 8px 3px 26px;
}


/***Success****/

.new-message-box-success {
    background: #eeeeee;
    padding: 3px;
    margin: 10px 0;
    
    background: transparent !important;
}

.tip-icon-success {
    background: #8BC34A;
}

.tip-box-success {
    color: #33691E;
    /*background: #75ff9487; */
    /*background: #75ff94; */
    background: #afffc1;
    border-radius: 3px;
    width: max-content !important;
    padding: 20px 40px 10px 40px;
    box-shadow: 0 30px 60px -25px rgb(0 0 0 / 45%);
}

.tip-icon-success::before {
    font-size: 25px;
    content: '\f00c';
    top: 8px;
    left: 11px;
    font-family: FontAwesome;
    position: absolute;
    color: white;
}

.tip-icon-success i::before {
    background: #8BC34A;
}


/*******Danger*******/
.new-message-box-danger {
    background: #eeeeee;
    padding: 3px;
    margin: 10px 0;
    background: transparent !important;
}

.tip-icon-danger {
    background: #f44336;
}

.tip-box-danger {
    color: #b71c1c;
    background: #FFCCBC;
    border-radius: 3px;
    width: max-content !important;
    padding: 20px 40px 10px 40px;
    box-shadow: 0 30px 60px -25px rgb(0 0 0 / 45%);
}

.tip-icon-danger::before {
    font-size: 25px;
    content:"\f00d";
    top: 8px;
    left: 11px;
    font-family: FontAwesome;
    position: absolute;
    color: white;
}

.tip-icon-danger i::before {
    background: #f44336;
}

/*******warning*******/
.new-message-box-warning {
    background: #eeeeee;
    padding: 3px;
    margin: 10px 0;
    background: transparent !important;
}

.tip-icon-warning {
    background: #FFEB3B;
}

.tip-box-warning {
    color: #212121;
    background: #FFF9C4;
    border-radius: 3px;
    width: max-content !important;
    padding: 20px 40px 10px 40px;
    box-shadow: 0 30px 60px -25px rgb(0 0 0 / 45%);
}

.tip-icon-warning::before {
    font-size: 25px;
    content:"\f071";
    top: 8px;
    left: 11px;
    font-family: FontAwesome;
    position: absolute;
    color: #212121;
}

.tip-icon-warning i::before {
    background: #FFEB3B;
}

/*******info*******/
.new-message-box-info {
    background: #eeeeee;
    padding: 3px;
    margin: 10px 0;
    background: transparent !important;
}

.tip-box-info {
    color: #01579B;
    background: #B3E5FC;
    border-radius: 3px;
    width: max-content !important;
    padding: 20px 40px 10px 40px;
    box-shadow: 0 30px 60px -25px rgb(0 0 0 / 45%);
}

.tip-icon-info {
    background: #03A9F4;
}

.tip-icon-info::before {
    font-size: 25px;
    content:"\f129";
    top: 8px;
    left: 11px;
    font-family: FontAwesome;
    position: absolute;
    color: white
}

.tip-icon-info i::before {
    background: #03A9F4;
}


/*******info*******/
.new-message-box-alert {
    background: #FF6F00;
    padding: 3px;
    margin: 10px 0;
    background: transparent !important;
}

.tip-box-alert {
    color: #212121;
    background: #FFF8E1;
    border-radius: 3px;
    width: max-content !important;
    padding: 20px 40px 10px 40px;
    box-shadow: 0 30px 60px -25px rgb(0 0 0 / 45%);
}

.tip-icon-alert {
    background: #FF6F00;
}

.tip-icon-alert::before {
    font-size: 25px;
    content:"\f06a";
    top: 8px;
    left: 11px;
    font-family: FontAwesome;
    position: absolute;
    color: white
}

.tip-icon-alert i::before {
    background: #FF6F00;
}