
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800&subset=latin');
@import url('https://fonts.googleapis.com/css?family=Nunito:100,200,300,400,500,600,700,800,900&subset=latin');
@import url('https://fonts.googleapis.com/css?family=Mr+Dafoe');

html, body, #app, #app, #root, #root > div {
    height: 100%
}

.title-holder {
    top: 50%;
    left: 40%;
    /*position: absolute; */
}

.stry-txt {
    /*text-transform: uppercase; */
    font-size: 38px;
    font-weight: 800;
    margin-bottom: 0px;
    /*color: #E50914;*/
    color: #1D1D1B;
    font-family: 'Nunito';
    width: 0;
    text-align: left;
    overflow: hidden;
    white-space: no-wrap;
    display: block;
    border-right: 2px solid #f9d566;
    outline: 4px;
    animation: typewriter 3s steps(6) infinite, blinking-cursor 0.5s infinite;
    animation-fill-mode: forwards;
}

@keyframes typewriter {
    0% {
        width: 0
    }

    25% {
        width: 70%
    }

    48% {
        width: 100%
    }

    50% {
        width: 100%
    }

    55% {
        width: 100%
    }

    75% {
        width: 70%;
    }

    85% {
        width: 30%;
    }

    100% {
        width: 0;
    }
}

@keyframes blinking-cursor {
    from {
        border-color: transparent
    }

    to {
        border-color: #1D1D1B;
    }
}



.mkr-text {
    font-family: 'Mr Dafoe';
    font-size: 28px;
    /*margin-top: -11px;*/
    color: #E50914;
    width: 100%;
    text-align:center;
}

.login-page-container {
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: 9%;
    background-image: url("back2.jpg");
    background-position: center center;
    background-size: cover;
}

.google-login-btn {
    margin-top:30px;
}

.logo-login {
    background-image: url('logo.png');
    background-repeat: no-repeat;
    width: 300px;
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    background-size: 90px;
    background-position: center;
}

.version {
    text-align:right;
}

.main-login {
    background-color: #FFFFFF;
    width: 400px;
    max-height: 400px;
    margin-left: 30px;
    /*padding: 7em auto; */
    border-radius: 1.5em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 20px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; 
    /*box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
    margin-right: 30px;
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
    height: 400px;
    border: 3px solid #e50913;
}

.sign {
    padding-top: 40px;
    color: rgb(48,60,84,255);
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 23px;
    display: flex;
    flex-direction: column;
}

.un 
{
    width: 76%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
}

form.form1 
{
    padding-top: 40px;
}

.pass {
        width: 76%;
color: rgb(38, 50, 56);
font-weight: 700;
font-size: 14px;
letter-spacing: 1px;
background: rgba(136, 126, 126, 0.04);
padding: 10px 20px;
border: none;
border-radius: 20px;
outline: none;
box-sizing: border-box;
border: 2px solid rgba(0, 0, 0, 0.02);
margin-bottom: 50px;
margin-left: 46px;
text-align: center;
margin-bottom: 27px;
font-family: 'Ubuntu', sans-serif;
}


.un:focus, .pass:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
    
}

.submit {
  cursor: pointer;
    border-radius: 5em;
    color: #fff;
    background: linear-gradient(to right, rgb(48,60,84,255), rgb(48,60,84,255));
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: 'Ubuntu', sans-serif;
    margin-left: 35%;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    padding-top: 15px;
}